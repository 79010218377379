import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 17" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 17</h1>
      <p>God morgen alle sammen!</p>
      <p>I går overhørte jeg dere snakke om at prompen min lukter av og til. Det er jeg lei meg for, men jeg må jo bare få det ut. Kanskje dette duftlyset kan brukes for å dekke over det verste av lukt?</p>
      <p>Love ya'll! XoXoXo</p>
      <p>Har jeg ikke blitt kul?</p>
      <p>Hilsen Litjen</p>
      <p>PS: I dag er jeg 18 uker! Får jeg gave? Tihiihiihiiii!</p>
    </div>
  </Layout>
)

export default LitjenLuke
